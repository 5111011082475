import { Button, useTheme } from "@primer/react";
import { switchTheme } from "../../interfaces/theme";

export default function ThemeSwitcher() {
  const {colorScheme, setColorMode} = useTheme();
  
  return (
    <Button onClick={() => switchTheme(colorScheme, setColorMode)}>
      Switch Theme
    </Button>
  )
}