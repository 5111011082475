import { useState } from "react";
import { Box, Button, PageLayout } from "@primer/react";
import { ThemeSwitcher } from "./components";

export default function App() {
  const [count, setCount] = useState(0);

  return (
    <PageLayout
      rowGap="none"
      columnGap="none"
      padding="none"
      containerWidth="full"
    >
      <PageLayout.Header
        padding="normal"
        divider="line"
      >
        <h1>Hello, World!</h1>
      </PageLayout.Header>
      <PageLayout.Content
        padding="normal"
      >
        <Button variant="primary" size="large" onClick={() => setCount(count + 1)}>
          {count < 1 ? "Click me!" : `You clicked me ${count} time${count > 1 ? "s" : ""}!`}
        </Button>
        <Button variant="link" size="small" onClick={() => setCount(0)}>Reset</Button>
      </PageLayout.Content>
      <PageLayout.Footer
        padding="normal"
        divider="line"
      >
        <ThemeSwitcher />
      </PageLayout.Footer>
    </PageLayout>
  );
}
