export function switchTheme(colorScheme?: string, setColorMode?: Function) {
  console.debug(`switchTheme: colorScheme=${colorScheme}, setColorMode=${setColorMode}`)

  console.debug("colorScheme:", colorScheme)
  console.debug("bodyColorMode:", document.body.getAttribute("data-color-mode"))

  const nextColorMode = colorScheme === "light" ? "dark" : "light"
  console.debug("nextColorMode:", nextColorMode)

  document.body.setAttribute("data-color-mode", nextColorMode)
  setColorMode!(nextColorMode)
}