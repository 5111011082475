import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider, BaseStyles } from "@primer/react";
import App from './App';
import './index.css';

const root = createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <ThemeProvider colorMode="auto">
      <BaseStyles>
        <App />
      </BaseStyles>
    </ThemeProvider>
  </StrictMode>
);